<!--  -->
<template>
  <div>
    <Banner :slideImgs="slideImgs"></Banner>
    <main>
    <!-- 智慧园区解决方案 -->
    <div class="container-fluid">
      <div class="common-btn zhihui-btn">智慧园区解决方案</div>
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <img src="@/assets/home/zhihui-1.png" alt="">
        </div>
        <p class="describe col-md-6 col-lg-6">
          智慧园区三维驾驶舱（数字孪生IOC），是以ShawyoPark OS（数字园区引擎）
          基础，为智慧园区的数字化转型增加动力。
          通过将园区管理桌面从2D升级为3D，集合BIM、CAD、IOT、ERP等数据，
          打通园区规划、设计推演、施工建设、品宣招商、持续运营、物业管理、
          应急管理等场景，助力园区建设方和运营方建设与运营智慧园区，
          提升管理效率，快速完成园区入驻指标，赋予持续的科学化可视化运营能力。
        </p>
      </div>
      <div class="list-menu">
        <div class="float-title">
          <div class="row" style="padding: 0 56px;">
            <div class="col-md-4 col-lg-4">
              <div class="btns common-btn">三维驾驶舱（数字孪生IOC）</div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="btns common-btn">智能运营中心-电脑端</div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="btns common-btn">智能运营中心-移动端(App)</div>
            </div>
          </div>
        </div>
        <div class="lists">
          <ul class="row middle-item">
            <li class="col-md-2 col-lg-2">
              <ul class="item">
                <li><i class="icon"></i>物联设备管理</li>
                <li>设备可视查询</li>
                <li>设备告警</li>
                <li>设备运维</li>
              </ul>
            </li>
            <li class="col-md-2 col-lg-2">
              <ul class="item">
                <li><i class="icon"></i>空间管理</li>
                <li>空间可视查询</li>
                <li>空间规划</li>
                <li>空间成本分析</li>
              </ul>
            </li>
            <li class="col-md-2 col-lg-2">
              <ul class="item">
                <li><i class="icon"></i>资产管理</li>
                <li>资产可视查询</li>
                <li>资产轨迹</li>
                <li>资产盘点</li>
              </ul>
            </li>
            <li class="col-md-2 col-lg-2">
              <ul class="item">
                <li><i class="icon"></i>能效管理</li>
                <li>能效监控和告警</li>
                <li>能效统计</li>
                <li>能效趋势分析</li>
              </ul>
            </li>
            <li class="col-md-2 col-lg-2">
              <ul class="item">
                <li><i class="icon"></i>综合安防</li>
                <li>视频实时监控</li>
                <li>事件中心</li>
                <li>人员、车辆布控</li>
              </ul>
            </li>
          </ul>
          <ul class="row footer-item">
            <li class="col-md-1 col-lg-1">
              <div class="item-2">
                <i class="icon"></i>智能运营
              </div>
            </li>
            <li class="col-md-1 col-lg-1">
              <div class="item-2">
                <i class="icon"></i>便捷通行
              </div>
            </li>
            <li class="col-md-1 col-lg-1">
              <div class="item-2">
                <i class="icon"></i>消防系统
              </div>
            </li>
            <li class="col-md-1 col-lg-1">
              <div class="item-2">
                <i class="icon"></i>楼宇智控
              </div>
            </li>
            <li class="col-md-1 col-lg-1">
              <div class="item-2">
                <i class="icon"></i>高效办公
              </div>
            </li>
            <li class="col-md-1 col-lg-1">
              <div class="item-2">
                <i class="icon"></i>公共服务
              </div>
            </li>
            <li class="col-md-1 col-lg-1">
              <div class="item-2">
                <i class="icon"></i>信息互动
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <!-- 设施监控 -->
    <div class="container-fluid section">
      <div class="row">
        <div class="left col-md-2 col-lg-2 remove-padding">
          <div class="common-btn btn-title-2">设施监控</div>
          <p>
            智慧园区设施监控系统
            我们打通园区几乎所有设施的数据接口，纵向解耦，横向整合，联接与控制分离。从单场景智能迈向全场景智能，从传统垂直子系统转变成链接、融合、开放的统一监控中心。
          </p>
        </div>
        <div class="right col-md-10 col-lg-10">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-lg-6 remove-padding padd-img">
                <img src="@/assets/home/jiankong-1.png" alt="">
              </div>
              <div class="col-md-5 col-lg-5 remove-padding">
                <img src="@/assets/home/jiankong-2.png" alt="">
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <ul class="row">
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>给排水系统</li>
                  <li>生活水</li>
                  <li>集水井</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>暖通空调</li>
                  <li>空调机组</li>
                  <li>低温冷冻水</li>
                  <li>锅炉热水</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>电力系统</li>
                  <li>低压配电</li>
                  <li>高压配电</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>智慧消防</li>
                  <li>信息总览</li>
                  <li>消防用水</li>
                  <li>消防风机</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>智慧安防</li>
                  <li>视频监控</li>
                  <li>门禁监控</li>
                  <li>电梯监控</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>智慧环境</li>
                  <li>气象环境</li>
                  <li>配电机房</li>
                  <li>电梯机房</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 智能运维 -->
    <div class="container-fluid section">
      <div class="row">
        <div class="left col-md-2 col-lg-2 remove-padding">
          <div class="common-btn btn-title-2">智能运维</div>
          <p>
            智慧园区智能运维系统
            我们打通园区几乎所有设施的数据接口，纵向解耦，横向整合，联接与控制分离。从单场景智能迈向全场景智能，从传统垂直子系统转变成链接、融合、开放的统一监控中心。
          </p>
        </div>
        <div class="right col-md-10 col-lg-10">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-lg-6 remove-padding padd-img">
                <img src="@/assets/home/yunwei-1.png" alt="">
              </div>
              <div class="col-md-5 col-lg-5 remove-padding">
                <img src="@/assets/home/yunwei-2.png" alt="">
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <ul class="row">
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>设备管理</li>
                  <li>设备台账</li>
                  <li>设备结构树</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>巡检管理</li>
                  <li>巡检模型</li>
                  <li>巡检路线</li>
                  <li>巡检计划</li>
                  <li>巡检任务</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>PM管理</li>
                  <li>PM模型</li>
                  <li>PM计划</li>
                  <li>PM任务</li>
                  <li>PM周期</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>维修管理</li>
                  <li>设备报修</li>
                  <li>维修工单</li>
                  <li>维修记录</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>流程中心</li>
                  <li>流程设计</li>
                  <li>新建流程模型</li>
                  <li>流程模型管理</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>备品备件</li>
                  <li>入库管理</li>
                  <li>入库记录</li>
                  <li>基值管理</li>
                  <li>库存台账</li>
                  <li>......</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 工单管理 -->
    <div class="container-fluid section">
      <div class="row">
        <div class="left col-md-2 col-lg-2 remove-padding">
          <div class="common-btn btn-title-2">工单管理</div>
          <p>
            智慧园区工单管理系统
            我们打通园区几乎所有设施的数据接口，纵向解耦，横向整合，联接与控制分离。从单场景智能迈向全场景智能，从传统垂直子系统转变成链接、融合、开放的统一监控中心。
          </p>
        </div>
        <div class="right col-md-10 col-lg-10">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-lg-6 remove-padding padd-img">
                <img src="@/assets/home/gongdan-1.png" alt="">
              </div>
              <div class="col-md-5 col-lg-5 remove-padding">
                <img src="@/assets/home/gongdan-2.png" alt="">
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <ul class="row">
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>工单总览 </li>
                  <li>工单统计</li>
                  <li>待办事项</li>
                  <li>日程安排</li>
                  <li>工单总览</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>我的工单</li>
                  <li>创建工单</li>
                  <li>待处理任务</li>
                  <li>已处理任务</li>
                  <li>我的申请</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>工单设计 </li>
                  <li>新建工单模版</li>
                  <li>工单模版管理</li>
                  <li>新建流程模型</li>
                  <li>流程模型管理</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>工单管理 </li>
                  <li>工单详情</li>
                  <li>我的申请</li>
                  <li>我的审核</li>
                  <li>所有审核</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>报表管理 </li>
                  <li>绩效报表</li>
                  <li>员工概况</li>
                  <li>指标统计</li>
                  <li>评价统计</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧能源 -->
    <div class="container-fluid section">
      <div class="row">
        <div class="left col-md-2 col-lg-2 remove-padding">
          <div class="common-btn btn-title-2">智慧能源</div>
          <p>
            智慧园区智慧能源管理系统
            我们打通园区几乎所有设施的数据接口，纵向解耦，横向整合，联接与控制分离。从单场景智能迈向全场景智能，从传统垂直子系统转变成链接、融合、开放的统一监控中心。
          </p>
        </div>
        <div class="right col-md-10 col-lg-10">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-lg-6 remove-padding padd-img">
                <img src="@/assets/home/nengyuan-1.png" alt="">
              </div>
              <div class="col-md-5 col-lg-5 remove-padding">
                <img src="@/assets/home/nengyuan-2.png" alt="">
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <ul class="row">
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>台账管理 </li>
                  <li>能源总览</li>
                  <li>数据大屏</li>
                  <li>仪表模型</li>
                  <li>数据字典</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>分区用能</li>
                  <li>绩效报表</li>
                  <li>员工概况</li>
                  <li>指标/评价统计</li>
                  <li>能源流向图</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>分项用能 </li>
                  <li>能耗查询</li>
                  <li>损耗查询</li>
                  <li>同比/环比分析</li>
                  <li>能源流向图</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>部门用能 </li>
                  <li>能耗查询</li>
                  <li>损耗查询</li>
                  <li>同比/环比分析</li>
                  <li>能源流向图</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>数据报表 </li>
                  <li>能耗报表</li>
                  <li>损耗报表</li>
                  <li>同比/环比分析</li>
                  <li>多维查询</li>
                  <li>设备能耗</li>
                </ul>
              </li>
              <li class="col-md-2 col-lg-2">
                <ul>
                  <li>报警中心 </li>
                  <li>报警配置</li>
                  <li>报警历史</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- 关于我们 -->
  <!-- <div class="line"></div>
  <div class="our">
    <div class="inner">
      <div class="top-logo">
        <a href="#">
          <img alt="小瑜科技" src="@/assets/home/our-logo.png">
        </a>
      </div>
      <div class="mi-describe">
        <p>
          成都小瑜科技有限公司(Chengdu ShawYo Technology Co.,Ltd.)</br>
          是一家提供互联网软件产品解决方案的提供商。公司创始人江汶钟先生从2004年从事软件研发行业，经过18年软件研发的经验积累，现已打造出一支互联网软件技术研发的纯技术团队。小瑜科技已经为超过1000家企业、军方、大型企业集团、中小企业、事业单位、个人创客提供了一站式的互联网解决方案和软件产品研发，在智慧园区、政务、教育、医疗、金融、旅游、电商等数十个行业领域积累了丰富的软件项目研发经验。在2020年，推出了新一代智慧园区的三维数据可视化决策与运营平台、智慧园区运营平台、手机APP等一系列产品。
        </p>
      </div>
      <div class="bottom-tag container-fluid">
        <div class="row">
          <div class="col-md-4 col-lg-4">2004年成立“成都高新区钟流一壶工作室”</div>
          <div class="col-md-4 col-lg-4">2010年成立“成都七号科技有限公司”</div>
          <div class="col-md-4 col-lg-4">2015年成立“成都小瑜科技有限公司”</div>
        </div>
        <div>
          <div class="tag-our">能力成熟度集成模型三级认证企业（CMMI 3）</div>
          <div class="tag-our">已取得近20项目软件著作权证书</div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="foot">
    <footer>
      <div class="future">
        <img src="@/assets/home/future.png" alt="">
      </div>
      <div class="info">
        <div class="top-logo">
          <a href="#">
            <img alt="小瑜科技" src="@/assets/home/footer-logo.png">
          </a>
        </div>
        <p class="foot-info">
          成都小瑜科技有限公司(Chengdu ShawYo Technology Co.,Ltd.)</br>
          地址:成都市高新区天府五街美年广场D座12层</br>
          电话:18080081007 / 028-66110006</br>
          备案号:蜀ICP备19024063号</br>
        </p>
      </div>
    </footer>

  </div> -->
  </div>
</template>

<script>
import Banner from "@/components/Banner/index.vue";
export default {
  data() {
    return {
      slideImgs: [
        require("@/assets/home/banner-1.jpg"),
        require("@/assets/home/banner-2.jpg"),
      ],
    };
  },

  components: {
    Banner,
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>

<style lang='scss' scoped>

main,
footer {
  // width: 1440px;
  padding: 15px;
  margin: 0 auto;
}
.banner,
.our {
  margin: 0 auto;
}

// banner部分
.banner {
  height: 783px;
}

// 中间重要部分
main {
  width: 1440px;
  .container-fluid {
    padding: 0;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  // 公共标题按钮样式
  // margin-top: 783px;
  .common-btn {
    font-size: 24px;
    background: #2E4892;
  }

  .zhihui-btn {
    width: 404px;
    margin: 35px auto 56px auto;
    border-radius: 41px;
    padding: 22px 41px;
    font-size: 38px;
  }

  .describe {
    color: #DDDDDD;
    line-height: 50px;
    font-size: 22px;
  }

  .list-menu {
    margin-top: 71px;
    background: rgba(31, 29, 54, 1);
    ;
    border-radius: 20px;

    .lists {
      text-align: center;
      padding: 53px;

      .icon {
        display: inline-block;
        width: 27px;
        height: 22px;
        transform: translateY(5px);
        // margin-top: 10px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 7px;
      }

      .middle-item>li {
        margin: 0 19px;

        &:nth-child(1) .icon {
          background-image: url('~@/assets/home/icons/icon-1.png');
        }

        &:nth-child(2) .icon {
          background-image: url('~@/assets/home/icons/icon-2.png');
        }

        &:nth-child(3) .icon {
          background-image: url('~@/assets/home/icons/icon-3.png');
        }

        &:nth-child(4) .icon {
          background-image: url('~@/assets/home/icons/icon-4.png');
        }

        &:nth-child(5) .icon {
          background-image: url('~@/assets/home/icons/icon-5.png');
        }
      }

      .item {
        width: 231px;
        height: 246px;
        padding: 29px 38px;
        background: #12021F;
        border-radius: 16px;
        font-size: 15.5px;

        li:first-child {
          font-size: 18.5px;
          background: transparent;
          border-radius: 0;
          margin-top: 0;
        }

        li {
          width: 154px;
          height: 31px;
          line-height: 31px;
          background: #1F1A38;
          margin-top: 24px;
          border-radius: 15px;
        }
      }

      .footer-item {
        margin-top: 42px;
      }

      .footer-item>li {
        margin: 0 38px;

        &:nth-child(1) {
          margin-left: 19px;
        }

        .item-2 {
          width: 154px;
          height: 47px;
          border: 1px solid transparent;
          line-height: 47px;
          font-size: 15px;
          background: #12021F;
          border-radius: 13px;
        }

        &:nth-child(1) .icon {
          background-image: url('~@/assets/home/icons/icon-6.png');
        }

        &:nth-child(2) .icon {
          background-image: url('~@/assets/home/icons/icon-7.png');
        }

        &:nth-child(3) .icon {
          background-image: url('~@/assets/home/icons/icon-8.png');
        }

        &:nth-child(4) .icon {
          background-image: url('~@/assets/home/icons/icon-9.png');
        }

        &:nth-child(5) .icon {
          background-image: url('~@/assets/home/icons/icon-10.png');
        }

        &:nth-child(6) .icon {
          background-image: url('~@/assets/home/icons/icon-11.png');
        }

        &:nth-child(7) .icon {
          background-image: url('~@/assets/home/icons/icon-12.png');
        }
      }
    }
  }

  .float-title {
    text-align: center;
    transform: translateY(-22px);

    .btns {
      width: 420px;
      height: 46px;
      line-height: 46px;
      border-radius: 23px;
    }
  }

  // 设施监控
  .section {
    margin-top: 78px;
  }

  .remove-padding {
    padding: 0;
  }

  .left {

    // width: 220px;
    p {
      font-size: 16px;
      width: 220px;
      line-height: 40px;
      padding: 0 10px;
    }

    .btn-title-2 {
      width: 220px;
      height: 58px;
      font-size: 28px;
      text-align: center;
      line-height: 58px;
      background: #2E4892;
      border-radius: 14px;
      margin-bottom: 54px;
    }
  }

  .right {
    &>div>ul>li>ul>li {
      font-size: 16px;
      text-align: center;
      margin-top: 19px;

      &:first-child {
        width: 154px;
        height: 47px;
        margin-top: 37px;
        text-align: center;
        line-height: 47px;
        font-size: 18px;
        background: #1F1D36;
        border-radius: 24px;
      }
    }
  }
}

.line {
  height: 1px;
  margin-top: 128px;
  background-color: rgba(53, 42, 63, 1);
}

// 关于我们
.our {
  // width: 1920px;
  background-image: url('~@/assets/home/our-backImg.png');
  background-repeat: no-repeat;
  background-size: 662px 422px;

  .inner {
    margin-left: 537px;
    padding: 50px 0 100px 0;

    .container-fluid {
      margin: 0;
    }

    .top-logo {
      transform: translateX(-80px);
    }

    .mi-describe {
      font-size: 19px;
      width: 1087px;
      line-height: 41px;
      margin-bottom: 62px;
    }

    .bottom-tag {
      font-size: 12px;
      width: 817px;

      &>div {
        height: 32px;
        line-height: 32px;

        &:first-child {
          text-align: center;
          width: 100%;
          background: rgba(39, 35, 70, 1);
          border-radius: 16px;
          margin-bottom: 23px;
        }

        .tag-our {
          // width: auto;
          display: inline-block;
          padding: 0 20px;
          margin-right: 42px;
          background: rgba(39, 35, 70, 1);
          border-radius: 16px;
        }

        &:last-child {
          margin-right: -15px;
          margin-left: -15px;
        }
      }

    }
  }
}


@media screen and (max-width: 1440px) {
  // .navbar-default
  .banner,
  .our
   {
    width: 1440px;
    margin: 0 auto;
  }

  .our {
    background-position: -150px 0;
  }

  .our .inner {
    margin-left: 320px;
  }
}

</style>